import { createElement } from 'react';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CloseOutlined,
  DownSquareOutlined, EditOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined,
  PlusOutlined, PlusSquareOutlined, MinusSquareOutlined,
} from '@ant-design/icons';
import { MenuProps, message as messageBox } from 'antd';
import { makeAutoObservable } from 'mobx';
import prompt from '../prompt';
import { Tablet } from './Tablet';
import { Curve, Track } from './Tracks';
import { templatesStore } from './templatesStore';
import { exportPdfStore } from './exportPdfStore';
import { ValueScaleType } from '../api/dto/TrackDto';
import { InnerChartType } from './SourceDataMap';
import { LocalizationStore } from './localizationStore';

const tabletMenu = (
  getString: (str: string) => string,
  tablet: Tablet,
  track: Track,
): Required<MenuProps>['items'] => [
  {
    label: getString('Autoscroll'),
    icon: createElement(DownSquareOutlined),
    key: 'autoscroll',
    onClick: () => (tablet.autoScroll
      ? tablet.setAutoScroll(false)
      : tablet.setAutoScroll(true)),
  },
  {
    label: getString('Show-all-data'),
    icon: createElement(SearchOutlined),
    key: 'showAllDate',
    onClick: () => tablet.doFitAll(),
  },
  {
    label: getString('Export-PDF'),
    icon: createElement(FilePdfOutlined),
    key: 'exportPDF',
    onClick: () => exportPdfStore.showModal(tablet),
  },
  { type: 'divider' },
  {
    label: getString('Save-template'),
    icon: createElement(SaveOutlined),
    key: 'saveTemplate',
    onClick: async () => {
      await templatesStore.saveTemplate(tablet.getTabletDto(tablet.tabletDto.id));
      messageBox.success(getString('TemplateSuccessfullySaved'));
    },
  },
  {
    label: getString('Save-template-as-new'),
    icon: createElement(SaveOutlined),
    key: 'saveNewNameTemplate',
    onClick: async () => {
      try {
        const name = await prompt({
          title: getString('Save-template-title'),
          placeholder: getString('Write-new-name'),
          value: tablet.templateData.templateName,
          rules: [
            {
              required: true,
              message: getString('Enter-name'),
            },
          ],
          modalProps: {
            width: '400px',
            cancelText: getString('Cancel'),
          },
        });
        await templatesStore.saveTemplate(tablet.getTabletDto(0), name);
        messageBox.success(getString('TemplateSuccessfullySaved'));
      } catch (e) {
        messageBox.error('Please enter name');
      }
    },
  },
  ...(tablet.coordinator.currentTrack?.params.valueScaleType === ValueScaleType.Comments ? [{
    label: getString('Create-comment'),
    icon: createElement(PlusOutlined),
    key: 'createComment',
    onClick: () => {
      const source = track?.sourcesSorted
        .find((s) => s.sourceData?.innerChartType === InnerChartType.Comments);
      if (source) {
        const key = Math.round(
          tablet.scale.pointToData(tablet.scale.offsetY + tablet.coordinator.y),
        );
        tablet.commentsController.createComment(key, source.sourceDto.externalId);
      }
    },
  }, {
    label: getString('Expand-comments'),
    icon: createElement(PlusSquareOutlined),
    key: 'expandComments',
    onClick: () => tablet.commentsController.expandAll(),
  }, {
    label: getString('Collapse-comments'),
    icon: createElement(MinusSquareOutlined),
    key: 'collapseComments',
    onClick: () => tablet.commentsController.collapseAll(),
  }] : []),
  { type: 'divider' },
  {
    label: getString('Edit-mode'),
    icon: createElement(EditOutlined),
    key: 'editMode',
    onClick: () => tablet.enableEditing(),
  },
  { type: 'divider' },
  {
    label: getString('Close-template'),
    icon: createElement(CloseOutlined),
    key: 'close',
    onClick: () => tablet.closeTablet(),
  },
];

const trackEditMenu = (
  getString: (str: string) => string,
  tablet: Tablet,
  track: Track,
): Required<MenuProps>['items'] => [
  {
    label: getString(tablet.params.orientation === 'vertical' ? 'Add-track-on-right' : 'Add-track-on-up'),
    icon: createElement(tablet.params.orientation === 'vertical' ? ArrowRightOutlined : ArrowUpOutlined),
    key: 'addToRight',
    onClick: () => tablet.tracks.add(track, 1, ValueScaleType.Linear),
    // children: [
    //   {
    //     label: getString('LineScale'),
    //     key: 'addToRight.lineScale',
    //     onClick: () => tablet.tracks.add(track, 1, ValueScaleType.Linear),
    //   },
    //   {
    //     label: getString('LogScale'),
    //     key: 'addToRight.logScale',
    //     onClick: () => tablet.tracks.add(track, 1, ValueScaleType.Log),
    //   },
    //   {
    //     label: getString('Text-scale'),
    //     key: 'addToRight.textScale',
    //     onClick: () => tablet.tracks.add(track, 1, ValueScaleType.Comments),
    //   },
    //   {
    //     label: getString('Numeric-scale'),
    //     key: 'addToRight.numericScale',
    //     onClick: () => tablet.tracks.add(track, 1, ValueScaleType.Numeric),
    //   },
    // ],
  },
  {
    label: getString(tablet.params.orientation === 'vertical' ? 'Add-track-on-left' : 'Add-track-on-down'),
    icon: createElement(tablet.params.orientation === 'vertical' ? ArrowLeftOutlined : ArrowDownOutlined),
    key: 'addToLeft',
    onClick: () => tablet.tracks.add(track, -1, ValueScaleType.Linear),
    // children: [
    //   {
    //     label: getString('LineScale'),
    //     key: 'addToLeft.lineScale',
    //     onClick: () => tablet.tracks.add(track, -1, ValueScaleType.Linear),
    //   },
    //   {
    //     label: getString('LogScale'),
    //     key: 'addToLeft.logScale',
    //     onClick: () => tablet.tracks.add(track, -1, ValueScaleType.Log),
    //   },
    //   {
    //     label: getString('Text-scale'),
    //     key: 'addToLeft.textScale',
    //     onClick: () => tablet.tracks.add(track, -1, ValueScaleType.Comments),
    //   },
    //   {
    //     label: getString('Numeric-scale'),
    //     key: 'addToLeft.numericScale',
    //     onClick: () => tablet.tracks.add(track, -1, ValueScaleType.Numeric),
    //   },
    // ],
  },
  { type: 'divider' },
  {
    label: getString('Finish-edit'),
    icon: createElement(EditOutlined),
    key: 'finishEdit',
    onClick: () => tablet.disableEditing(),
  },
  { type: 'divider' },
  {
    label: getString('Remove-track'),
    icon: createElement(EditOutlined),
    key: 'removeTrack',
    onClick: () => tablet.tracks.removeTrack(track),
  },
];

const sourceEditMenu = (
  getString: (str: string) => string,
  tablet: Tablet,
  track: Track,
  source: Curve,
): Required<MenuProps>['items'] => [
  {
    label: getString('Finish-edit'),
    icon: createElement(EditOutlined),
    key: 'finishEdit',
    onClick: () => tablet.disableEditing(),
  },
  { type: 'divider' },
  {
    label: getString('Remove-curve'),
    icon: createElement(EditOutlined),
    key: 'removeCurve',
    onClick: () => track.removeSource(source),
  },
];

export class ContextMenuStore {
  currentMenu: Required<MenuProps>['items'] | null = null;

  x: number = 0;

  y: number = 0;

  getString: (str: string) => string;

  constructor(localizationStore: LocalizationStore) {
    this.getString = (message: string) => (localizationStore.l10n
      ? localizationStore.l10n?.getString(message)
      : message);
    makeAutoObservable(this);
  }

  openTabletMenu(x :number, y: number, tablet: Tablet, track: Track) {
    this.x = x;
    this.y = y;
    this.currentMenu = tabletMenu(this.getString, tablet, track);
  }

  openTrackEditMenu(x :number, y: number, tablet: Tablet, track: Track) {
    this.x = x;
    this.y = y;
    this.currentMenu = trackEditMenu(this.getString, tablet, track);
  }

  openSourceEditMenu(x :number, y: number, tablet: Tablet, track: Track, source: Curve) {
    this.x = x;
    this.y = y;
    this.currentMenu = sourceEditMenu(this.getString, tablet, track, source);
  }

  reset() {
    this.x = 0;
    this.y = 0;
    this.currentMenu = null;
  }
}
