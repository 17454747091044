import {
  IReactionDisposer,
  action,
  makeAutoObservable, reaction, runInAction, when,
} from 'mobx';
import { TabletDto } from '../api/dto/TabletDto';
import { getScale, Scale } from './Scale';
import { Tracks } from './Tracks';
import { Range } from '../utils';
import { Coordinator } from './Coordinator';
import { SourceDataMap } from './SourceDataMap';
import { CommentsController } from './Comments/CommentsController';
import { CommentsCoordinates } from './CoordinatesStorages/CommentsCoordinates';
import { CoordinatorRange } from './CoordinatorRange';
import { ScaleTimeline } from './ScaleTimeline';
import { ScaleDepthLine } from './ScaleDepthLine';
import { TabletType } from '../enums/TabletType';
import { TabletParams } from './TabletParams';
import { ScalePosition } from './ScalePosition';
import { TabletScroll } from './TabletScroll';
import { TabletSizes } from './TabletSizes';
import { BASE_LEGEND_HEIGHT, COLLAPSED_LEGEND_HEIGHT } from '../constants';
import { TracksAnalysisCoordinator } from './TracksAnalysisCoordinator';
import { TimeZonesStore } from './timeZonesStore';
import { sleepDetect } from '../sleep-detect';

export interface FolderShort {
  externalId: number;
  text?: string;
}

export class Tablet {
  loading = false;

  tabletDto: TabletDto;

  templateData: {
    well: FolderShort;
    bole: FolderShort;
    log?: FolderShort | null;
    templateName?: string;
  };

  tabletType: TabletType;

  coordinator: Coordinator;

  scale: Scale;

  autoScroll: boolean = false;

  tracks: Tracks;

  sourceDataMap: SourceDataMap;

  trackInfoCollapsed = false;

  trackInfoVisible = true;

  closeTablet: () => void;

  fullscreen = false;

  editMode = false;

  serviceInfo = false;

  baseTextStyle = {
    fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
    fontSize: 14,
    fontWeight: '400' as const,
  };

  commentsController: CommentsController;

  range: CoordinatorRange | null = null;

  tracksAnalysis: TracksAnalysisCoordinator | null = null;

  glAvailable = true;

  mode: 'tablet' | 'pdf' = 'tablet';

  scaleLine: ScaleTimeline | ScaleDepthLine;

  maxZoom: number;

  params: TabletParams;

  scalePosition: ScalePosition;

  tabletSizes: TabletSizes;

  tabletScroll: TabletScroll;

  timeZones: TimeZonesStore;

  reactionDisposerMaxKey: IReactionDisposer | null = null;

  constructor(
    timeZonesStore: TimeZonesStore,
    tabletDto: TabletDto,
    closeTablet: (tablet: Tablet) => void,
    templateData: Tablet['templateData'],
    mode: 'tablet' | 'pdf' = 'tablet',
  ) {
    this.timeZones = timeZonesStore;

    this.tabletDto = tabletDto;
    this.templateData = templateData;

    this.tabletType = this.tabletDto.tracksets[0].tracksetType;
    this.params = new TabletParams(this.tabletType);
    this.params.setOrientation(this.tabletDto.tracksets[0].orientation);
    this.scalePosition = new ScalePosition(this.tabletType, this.params);
    this.scale = getScale(this.tabletType, this.params);
    this.mode = mode;

    if (this.tabletType === TabletType.Depth) {
      this.scaleLine = new ScaleDepthLine(this.scale, this.baseTextStyle.fontSize, this.params);
      this.maxZoom = 0.015;
    } else if (this.tabletType === TabletType.Time) {
      this.scaleLine = new ScaleTimeline(
        this.scale,
        this.timeZones,
        this.baseTextStyle.fontSize,
        this.params,
      );
      this.maxZoom = 0.001;
    }

    this.sourceDataMap = new SourceDataMap(this.tabletType, this.scale);

    this.tracks = new Tracks(
      this.tabletDto.tracksets[0].tracks.filter((track) => track.sources.length),
      this.sourceDataMap,
    );

    this.tabletSizes = new TabletSizes(this.params, this.tracks);

    this.tabletScroll = new TabletScroll(this.params, this.tabletSizes, this.scalePosition);

    makeAutoObservable(this, {
      endTracksAnalysis: action.bound,
      reactionDisposerMaxKey: false,
    });

    if (mode === 'tablet') {
      this.init();

      this.coordinator = new Coordinator(
        this.tracks,
        this.params,
        this.scalePosition,
        this.tabletScroll,
      );

      this.closeTablet = () => closeTablet(this);
    }

    this.commentsController = new CommentsController(this.tabletType, {
      removePoint: this.removePoint.bind(this),
      getTrackRange: this.getTrackRange.bind(this),
    });
  }

  async init() {
    await when(() => this.height > 1);

    this.sourceDataMap.fetchSources().then(() => {
      this.scale.setZeroPoint(this.toDataDB);
      if (this.tabletType === TabletType.Time) {
        this.setOffsetToEnd();
      } else {
        this.fitCurvesToScreen();
      }
      this.refreshCoords(true);
    });

    this.reactionDisposerMaxKey = reaction(() => this.toDataDB, () => {
      if (this.autoScroll) {
        this.setOffsetToEnd();
      }
    });

    sleepDetect.subscribe('WakeUp', () => {
      // eslint-disable-next-line no-console
      console.info('WakeUp', {
        'on-line': navigator.onLine,
        'document.hidden': document.hidden,
        visibilityState: document.visibilityState,
        time: Date.now(),
      });
      if (this.sourceDataMap.metaInfoLoading) {
        return;
      }
      this.sourceDataMap.fetchSources().then(() => {
        this.refreshCoords(false, true);
      });
    });
  }

  get onDataDB() {
    return this.sourceDataMap.onDataDB;
  }

  get toDataDB() {
    return this.sourceDataMap.toDataDB;
  }

  get height() {
    return this.scale.containerLength;
  }

  get topY() {
    if (this.tabletType === TabletType.Time) {
      const diffMinutes = (this.onDataDB - this.scale.zeroPointKey) / 60000.0;
      return diffMinutes / this.scale.dataPerPixel;
    }
    return Math.floor((this.onDataDB - this.scale.zeroPointKey) / this.scale.dataPerPixel);
  }

  get bottomY() {
    if (this.tabletType === TabletType.Time) {
      const diffMinutes = (this.toDataDB - this.scale.zeroPointKey) / 60000.0;
      return diffMinutes / this.scale.dataPerPixel;
    }
    return Math.floor((this.toDataDB - this.scale.zeroPointKey) / this.scale.dataPerPixel);
  }

  get topOffsetY() {
    return this.topY - this.offsetToEnd;
  }

  get bottomOffsetY() {
    return this.bottomY - this.scale.containerLength + this.offsetToEnd;
  }

  get scaleHeight() {
    return this.bottomOffsetY - this.topOffsetY;
  }

  get onFrame() {
    return this.scale.onFrame;
  }

  get toFrame() {
    return this.scale.toFrame;
  }

  get headerHeight() {
    if (!this.trackInfoVisible) {
      return 0;
    }
    return this.tracks.list.reduce((acc, current) => {
      const height = current.topSources.reduce((sum, source) => {
        if (source.params.showValueScale) {
          if (this.params.orientation === 'horizontal') {
            return sum + source.params.legendHeight;
          }
          return sum + BASE_LEGEND_HEIGHT;
        }
        return sum + (this.trackInfoCollapsed ? COLLAPSED_LEGEND_HEIGHT : BASE_LEGEND_HEIGHT);
      }, 0);
      return height > acc ? height : acc;
    }, 0);
  }

  get headerHeightTop() {
    if (!this.trackInfoVisible) {
      return 0;
    }
    return this.tracks.list.reduce((acc, current) => {
      const height = current.topSources.reduce((sum, source) => {
        if (source.params.showValueScale) {
          if (this.params.orientation === 'horizontal') {
            return sum + source.params.legendHeight;
          }
          return sum + BASE_LEGEND_HEIGHT;
        }
        return sum + (this.trackInfoCollapsed ? COLLAPSED_LEGEND_HEIGHT : BASE_LEGEND_HEIGHT);
      }, 0);
      return height > acc ? height : acc;
    }, 0);
  }

  get headerHeightBottom() {
    if (!this.trackInfoVisible) {
      return 0;
    }
    return this.tracks.list.reduce((acc, current) => {
      const height = current.bottomSources.reduce((sum, source) => {
        if (source.params.showValueScale) {
          if (this.params.orientation === 'horizontal') {
            return sum + source.params.legendHeight;
          }
          return sum + BASE_LEGEND_HEIGHT;
        }
        return sum + (this.trackInfoCollapsed ? COLLAPSED_LEGEND_HEIGHT : BASE_LEGEND_HEIGHT);
      }, 0);
      return height > acc ? height : acc;
    }, 0);
  }

  get minHeaderHeight() {
    if (!this.trackInfoVisible) {
      return 0;
    }
    return this.tracks.list.reduce((acc, current) => {
      const height = current.sourcesSorted.length
        * (this.trackInfoCollapsed ? COLLAPSED_LEGEND_HEIGHT : BASE_LEGEND_HEIGHT);
      return height < acc ? height : acc;
    }, Number.MAX_SAFE_INTEGER);
  }

  get offsetToEnd() {
    return this.scale.containerLength * 0.1;
  }

  get isMaxZoom() {
    return this.scale.dataPerPixel === this.maxZoom;
  }

  get isMinZoom() {
    return this.scale.dataPerPixel === this.minZoom;
  }

  get sharedCacheLoader() {
    return this.sourceDataMap.sharedCacheLoader;
  }

  get sharedPointsLoading() {
    return this.sourceDataMap.sharedPointsLoader;
  }

  get minZoom() {
    const range = (this.toDataDB - this.onDataDB) / (this.scale.containerLength * 0.8);

    let density: number;
    if (this.tabletType === TabletType.Depth) {
      density = Math.floor(range * 100) / 100;
    } else {
      density = Math.floor((range / 60000) * 10) / 10;
    }
    if (density === 0) {
      return 0.1;
    }
    return density;
  }

  get sharedLoadingRange() {
    if (!this.sharedPointsLoading) {
      return null;
    }

    const boundary = new Range(
      Math.max(this.scale.onFrame, this.sourceDataMap.onDataDB),
      Math.min(this.scale.toFrame, this.sourceDataMap.toDataDB),
    );

    const { loadedRange } = this.sourceDataMap;

    if (loadedRange === null) {
      return boundary;
    }

    if (loadedRange.isTargetIn(boundary)) {
      return null;
    }

    const loaded = new Range(
      Math.max(this.scale.onFrame, loadedRange.start),
      Math.min(this.scale.toFrame, loadedRange.end),
    );

    if (loaded.start > boundary.start) {
      return new Range(boundary.start, loaded.start);
    }

    if (loaded.end < boundary.end) {
      return new Range(loaded.end, boundary.end);
    }
    return boundary;
  }

  refreshCoords(force = false, forceSolid?: boolean) {
    this.sourceDataMap.refreshCoords(this.onFrame, this.toFrame, force, undefined, forceSolid);
  }

  close() {
    if (this.reactionDisposerMaxKey) {
      this.reactionDisposerMaxKey();
    }
    this.tracks.removeTracks();
    this.closeTablet();
  }

  fitCurvesToScreen() {
    this.scale.setDensity(this.minZoom);
    if (this.autoScroll) {
      this.setOffsetToEnd();
    } else {
      this.setOffsetToCenter();
    }
  }

  fitToRange(range: number) {
    let density = Math.ceil(range / this.scale.containerLength);
    density /= (this.tabletType === TabletType.Time ? 60000 : 1);
    density = (Math.floor(density * 10000) / 10000);
    density = Math.min(density, this.minZoom);
    density = Math.max(density, this.maxZoom);

    this.scale.setDensity(density);
  }

  doFitAll() {
    this.fitCurvesToScreen();
    this.refreshCoords();
  }

  navigateTo(to: number, align: 'top' | 'bottom' | 'center') {
    const y = this.scale.dataToPoint(to);
    if (align === 'center') {
      this.scale.setOffsetY(y - (this.scale.containerLength / 2));
    } else if (align === 'top') {
      this.scale.setOffsetY(y - this.scale.containerLength);
    } else {
      this.scale.setOffsetY(y);
    }
  }

  setOffsetToEnd() {
    const newOffset = this.scale.dataToPoint(this.toDataDB || 0) - this.scale.containerLength;
    this.scale.setOffsetY(newOffset + this.offsetToEnd);
  }

  setOffsetToCenter() {
    const center = -this.scale.containerLength / 2 - (this.bottomY - this.topY) / 2;
    this.scale.setOffsetY(center);
  }

  scroll(deltaY: number) {
    const offset = deltaY < 0 ? -60 : 60;
    let nextOffset = this.scale.offsetY + offset;
    nextOffset = Math.max(nextOffset, this.topOffsetY);
    nextOffset = Math.min(nextOffset, this.bottomOffsetY);
    this.scale.setOffsetY(nextOffset);

    if (this.tabletType === TabletType.Time) {
      this.refreshCoords();
    }
  }

  zoom(layerY: number, zoomDirection: number) {
    const markTime = this.scale.pointToData(
      layerY + this.scale.offsetY,
    );

    const cursorYOld = this.scale.dataToPoint(markTime) + this.headerHeight;

    let nextZoom = Math.round(
      this.scale.dataPerPixel * (1.03 ** (zoomDirection * -1)) * 100000,
    ) / 100000;
    nextZoom = Math.min(nextZoom, this.minZoom);
    nextZoom = Math.max(nextZoom, this.maxZoom);
    this.scale.setDensity(nextZoom);

    const cursorYNew = this.scale.dataToPoint(markTime) + this.headerHeight;
    let nextOffset = Math.round(this.scale.offsetY + (cursorYNew - cursorYOld));
    if (nextOffset > this.bottomOffsetY) {
      nextOffset = this.bottomOffsetY;
    } else if (nextOffset < this.topOffsetY) {
      nextOffset = this.topOffsetY;
    }

    this.scale.setOffsetY(nextOffset);

    if (this.autoScroll) {
      this.setOffsetToEnd();
    }

    this.refreshCoords();
  }

  zoomIn() {
    this.zoom(this.scale.containerLength / 2, 1);
  }

  zoomOut() {
    this.zoom(this.scale.containerLength / 2, -1);
  }

  setOrientation(orientation: 'vertical' | 'horizontal') {
    const centerDate = this.scale.pointToData(
      this.scale.offsetY + (this.scale.containerLength / 2),
    );
    this.params.setOrientation(orientation);

    let newDataPerPixel = this.scale.dataPerPixel;
    newDataPerPixel = Math.max(newDataPerPixel, this.maxZoom);
    newDataPerPixel = Math.min(newDataPerPixel, this.minZoom);
    this.scale.setDensity(newDataPerPixel);

    let newOffset = this.scale.dataToPoint(centerDate) - this.scale.containerLength / 2;
    newOffset = Math.max(newOffset, this.topOffsetY);
    newOffset = Math.min(newOffset, this.bottomOffsetY);
    this.scale.setOffsetY(newOffset);

    this.tabletScroll.setScrollTop(0);
  }

  toggleTrackInfoVisible() {
    this.trackInfoVisible = !this.trackInfoVisible;
  }

  setTrackInfoCollapsed(value: boolean) {
    this.trackInfoCollapsed = value;
  }

  toggleTrackInfoCollapsed() {
    this.setTrackInfoCollapsed(!this.trackInfoCollapsed);
  }

  setFullscreen(fullscreen: boolean) {
    this.fullscreen = fullscreen;
  }

  setAutoScroll(autoScroll: boolean) {
    if (autoScroll) {
      this.setOffsetToEnd();
      this.refreshCoords();
    }
    this.autoScroll = autoScroll;
  }

  setServiceInfo(visible: boolean) {
    this.serviceInfo = visible;
  }

  runGlNotAvailable() {
    this.glAvailable = false;
    setTimeout(() => {
      runInAction(() => {
        this.glAvailable = true;
      });
    }, 100);
  }

  enableEditing() {
    this.editMode = true;
  }

  disableEditing() {
    this.editMode = false;
    this.tracks.setSelected(null);
    this.tracks.setSelectedSource(null);
  }

  getTabletDto(id: number) {
    const tabletDto = new TabletDto();
    tabletDto.name = this.tabletDto.name;
    tabletDto.id = id;
    tabletDto.storageVersion = this.tabletDto.storageVersion;
    tabletDto.tracksets = [this.tracks.getTracksetDto()];
    if (tabletDto.tracksets[0]) {
      tabletDto.tracksets[0].tracksetType = this.tabletDto.tracksets[0].tracksetType;
      tabletDto.tracksets[0].orientation = this.params.orientation;
    }
    return tabletDto;
  }

  getTrackRange(onRangeSelected: (range: Range) => void) {
    const onRangeSelectionFinished = (r: Range) => {
      const newRange = new Range(
        this.scale.pointToData(r.start),
        this.scale.pointToData(r.end),
      );
      onRangeSelected(newRange);
      runInAction(() => {
        this.range = null;
      });
    };
    this.range = new CoordinatorRange(
      this.coordinator,
      this.scale,
      onRangeSelectionFinished,
    );

    this.range.start();
  }

  endTracksAnalysis() {
    this.tracksAnalysis = null;
  }

  startTracksAnalysis() {
    this.tracksAnalysis = new TracksAnalysisCoordinator(
      this.coordinator,
      this.scale,
      this.endTracksAnalysis,
    );

    this.tracksAnalysis.start();
  }

  removePoint(sourceId: number, key: number) {
    const source = this.sourceDataMap.map.get(sourceId);
    if (source && source.curveCoordinates instanceof CommentsCoordinates) {
      const { points } = source.curveCoordinates;
      const removeIndex = points.findIndex((p) => p.key === key);
      if (removeIndex > -1) {
        const p = points.slice();
        p.splice(removeIndex, 1);
        source.curveCoordinates.points = p;
      }
    }
  }
}
