import { makeAutoObservable } from 'mobx';
import { Tablet } from './Tablet';

export class ExportPdfStore {
  errors = null;

  isModalVisible = false;

  isLoading = false;

  tablet: Tablet;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading() {
    this.isLoading = true;
  }

  unsetLoading() {
    this.isLoading = false;
  }

  showModal = (tablet: Tablet) => {
    this.tablet = tablet;
    this.isModalVisible = true;
  };

  hideModal() {
    this.isModalVisible = false;
  }
}

export const exportPdfStore = new ExportPdfStore();
