import { useLocalization } from '@fluent/react';
import {
  Form, Select, InputNumber, Checkbox, Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import {
  CommentsHeight, Layers, TrackAlign, ValueScaleType,
} from '../../api/dto/TrackDto';
import { InnerChartType } from '../../stores/SourceDataMap';
import { Track } from '../../stores/Tracks';

export const TrackParams = observer(({ track } : { track: Track }) => {
  const { l10n } = useLocalization();
  const [form] = Form.useForm();

  const commentsDisabled = track.sources.length > 0;
  const numericDisabled = track.sources.length > 1 || track.sources
    .find((s) => s.sourceData && !(s.sourceData.innerChartType === InnerChartType.DepthCurve
      || s.sourceData.innerChartType === InnerChartType.TimeCurve));

  useEffect(() => {
    form.setFieldValue('trackWidth', track.params.trackWidth);
  }, [form, track.params.trackWidth]);

  return (
    <Form
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      form={form}
      onValuesChange={(value) => track.setParams(value)}
      initialValues={track.params}
      size="small"
    >
      <Typography.Title level={5} style={{ marginBottom: 16 }}>
        {l10n.getString('TrackSettings')}
      </Typography.Title>
      <Form.Item name="trackWidth" label={l10n.getString('Width')}>
        <InputNumber min={40} />
      </Form.Item>
      <Form.Item name="valueScaleType" label={l10n.getString('TypeScale')}>
        <Select>
          <Select.Option value={ValueScaleType.Linear}>{l10n.getString('LineScale')}</Select.Option>
          <Select.Option value={ValueScaleType.Log}>{l10n.getString('LogScale')}</Select.Option>
          <Select.Option disabled={commentsDisabled} value={ValueScaleType.Comments}>{l10n.getString('Text-scale')}</Select.Option>
          <Select.Option disabled={numericDisabled} value={ValueScaleType.Numeric}>{l10n.getString('Numeric-scale')}</Select.Option>
        </Select>
      </Form.Item>
      {track.params.valueScaleType === ValueScaleType.Numeric && (
        <Form.Item name="align" label={l10n.getString('ValuePosition')}>
          <Select>
            <Select.Option value={TrackAlign.Right}>{l10n.getString('Right')}</Select.Option>
            <Select.Option value={TrackAlign.Left}>{l10n.getString('Left')}</Select.Option>
          </Select>
        </Form.Item>
      )}
      {track.params.valueScaleType === ValueScaleType.Comments && (
      <>
        <Form.Item name="fontSize" label={l10n.getString('Font-size')}>
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item name="layersPriority" label={l10n.getString('Layers-Priority')}>
          <Select>
            <Select.Option value={Layers.Single}>{l10n.getString('Single')}</Select.Option>
            <Select.Option value={Layers.Multi}>{l10n.getString('Multi')}</Select.Option>
          </Select>
        </Form.Item>
        {track.params.layersPriority === Layers.Multi && (
        <Form.Item name="commentsHeight" label={l10n.getString('Comments-height')}>
          <Select>
            <Select.Option value={CommentsHeight.ByText}>{l10n.getString('By-Text')}</Select.Option>
            <Select.Option value={CommentsHeight.ByRange}>{l10n.getString('By-Range')}</Select.Option>
          </Select>
        </Form.Item>
        )}
        <Form.Item name="hideBorders" valuePropName="checked" wrapperCol={{ span: 24 }}>
          <Checkbox>{l10n.getString('Hide-borders')}</Checkbox>
        </Form.Item>
      </>
      )}
      {(track.params.valueScaleType === ValueScaleType.Linear
        || track.params.valueScaleType === ValueScaleType.Log) && (
          <>
            <Form.Item name="gridBaseVert" label={l10n.getString('Scale-gen')}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="gridSecondVert" label={l10n.getString('Scale-add')}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="hideCurves" valuePropName="checked" wrapperCol={{ span: 24 }}>
              <Checkbox>{l10n.getString('Hide-empty-curves')}</Checkbox>
            </Form.Item>
          </>
      )}
    </Form>
  );
});
