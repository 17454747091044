import { CloseOutlined } from '@ant-design/icons';
import { useLocalization } from '@fluent/react';
import { Button } from 'antd';
import {
  format, formatDistanceStrict, intervalToDuration,
} from 'date-fns';
import ru from 'date-fns/esm/locale/ru';
import { memo } from 'react';
import { TabletType } from '../../enums/TabletType';
import { localizationStore, scaleUnitStore, timeZonesStore } from '../../stores/init-stores-global';
import {
  AnalysisClose,
  AnalysisHeader, AnalysisTable, AnalysisTabletName, AnalysisTabletHeader,
  AnalysisTabletRow, AnalysisTabletValue,
} from './TracksAnalysis.styled';
import { LineProps } from './types';

type Props = {
  lines: (LineProps | null)[];
  close: () => void;
  from: number | null;
  to: number | null;
  tabletType: TabletType;
};
export const TracksAnalysisBody = memo(
  ({
    lines, close, from, to, tabletType,
  }: Props) => {
    const { l10n } = useLocalization();
    if (from == null || to == null) {
      return null;
    }
    const [start, end] = from > to ? [to, from] : [from, to];
    const isPoints = lines[0] ? lines[0].type === 'point' : true;
    return (
      <>
        <AnalysisClose><Button onClick={() => close()} size="small" icon={<CloseOutlined />} /></AnalysisClose>
        {lines[0]?.firstKey && lines[0]?.lastKey && (
        <AnalysisHeader>
          <div>{l10n.getString('Start')}</div>
          <div>
            {tabletType === TabletType.Time
              ? format(timeZonesStore.getDate(start), 'yyyy-MM-dd HH:mm:ss') : formatDepth(start)}
          </div>
          <div>{l10n.getString('End')}</div>
          <div>
            {tabletType === TabletType.Time
              ? format(timeZonesStore.getDate(end), 'yyyy-MM-dd HH:mm:ss') : formatDepth(end)}
          </div>
          <div>△</div>
          <div>
            {tabletType === TabletType.Time
              ? formatInterval(start, end) : formatDepthDelta(start, end)}
          </div>
        </AnalysisHeader>
        )}
        <AnalysisTable columnsCount={isPoints ? 7 : 3}>
          <AnalysisTabletName />
          {isPoints && (
            <>
              <AnalysisTabletHeader>{l10n.getString('Start')}</AnalysisTabletHeader>
              <AnalysisTabletHeader>{l10n.getString('End')}</AnalysisTabletHeader>
              <AnalysisTabletHeader>△</AnalysisTabletHeader>
            </>
          )}
          <AnalysisTabletHeader>{l10n.getString('Min')}</AnalysisTabletHeader>
          <AnalysisTabletHeader>{l10n.getString('Max')}</AnalysisTabletHeader>
          {isPoints && (
            <AnalysisTabletHeader>{l10n.getString('Average')}</AnalysisTabletHeader>
          )}
          {lines.map((line) => line && (
            <AnalysisTabletRow color={line.color} key={line.innerId}>
              <AnalysisTabletName>{line.mnemonic}</AnalysisTabletName>
              {isPoints && (
                <>
                  <AnalysisTabletValue>{line.firstValue}</AnalysisTabletValue>
                  <AnalysisTabletValue>{line.lastValue}</AnalysisTabletValue>
                  <AnalysisTabletValue>{line.delta}</AnalysisTabletValue>
                </>
              )}
              <AnalysisTabletValue>{line.min}</AnalysisTabletValue>
              <AnalysisTabletValue>{line.max}</AnalysisTabletValue>
              {isPoints && (
                <AnalysisTabletValue>{line.average}</AnalysisTabletValue>
              )}
            </AnalysisTabletRow>
          ))}
        </AnalysisTable>
      </>
    );
  },
);

const simpleFormat = (value: number) => String(value).padStart(2, '0');

function formatInterval(start:number, end:number) {
  const d = intervalToDuration({
    start,
    end,
  });

  let started = false;
  let str = '';

  if (d.days) {
    const days = formatDistanceStrict(start, end, {
      unit: 'day',
      roundingMethod: 'ceil',
      locale: localizationStore.locales['ru-Ru'] ? ru : undefined,
    });
    str += `${days} `;
    started = true;
  }
  if (d.hours || started) {
    str += `${simpleFormat(d.hours || 0)}:`;
    started = true;
  }
  if (d.minutes || started) {
    str += `${simpleFormat(d.minutes || 0)}:`;
    started = true;
  }
  if (d.seconds || started) {
    str += `${simpleFormat(d.seconds || 0)}`;
  }
  return str;
}

function formatDepth(value: number) {
  return Number((value * scaleUnitStore.kUnit).toFixed(1));
}

function formatDepthDelta(start: number, end: number) {
  const delta = formatDepth(end) - formatDepth(start);
  return formatDepth(delta);
}
