import useResizeObserver from 'use-resize-observer';
import { observer } from 'mobx-react-lite';
import {
  useCallback, useEffect, useMemo, useRef, useLayoutEffect, useState,
} from 'react';
import { useDrop } from 'react-dnd';
import { ThemeContext } from '@emotion/react';
import * as PIXI from 'pixi.js';
import { Sprite, Stage as PixiStage } from '@inlet/react-pixi';
import { FluentContext } from '@fluent/react/esm/context';
import { useGesture } from '@use-gesture/react';
import {
  PointsLoadSpiner, TabletBody, TabletWrapper,
} from './Tablet.styled';
import { TimeMainScale } from './TimeMainScale';
import { DepthScale } from './DepthScale';
import { Tablet as TabletEntity } from '../../stores/Tablet';
import { TabletTracksInfo } from '../TabletTracksInfo';
import { useFullscreen } from '../../hooks';
import { TabletHeader } from './TabletHeader';
import { Coordinator } from './Coordinator';
import { CoordinatorScale } from './CoordinatorScale';
import { CoordinatorTooltip } from '../CoordinatorTooltip';
import { ContextMenuStore } from '../../stores/ContextMenu';
import { ContextMenu } from './ContextMenu';
import { CommentModal } from './CommentModal';
import { ExportPdfModal } from '../ExportPdf/components/Modal';
import { Stage } from './Stage';
import { NavigationLine } from './NavigationLIne';
import { ScaleDevMonitor } from './ScaleDevMonitor';
import RotatingLines from '../Loader/RotatingLines';
import { TimeFullScale } from './TimeFullScale';
import { TabletType } from '../../enums/TabletType';
import { NavigationTimeLine } from './NavigationLIneTime';
import { ScrollContainer } from './ScrollContainer';
import { Tracks } from './Tracks';
import { ScrollBarHeight } from './ScrollBarHeight';
import { TrackSwitcher } from './TrackSwitcher';
import { TracksBorders } from './TracksBorders';
import { OverlayTracks } from './OverlayTracks';
import { TracksAnalysis } from '../TracksAnalysis/TracksAnalysis';
import { LocalizationStore } from '../../stores/localizationStore';
import { ThemeHex } from '../../types/ThemeHex';
import { TabletProvider } from './TabletProvider';
import { TimeZonesStore } from '../../stores/timeZonesStore';
import { ScaleUnitStore } from '../../stores/scaleUnitStore';

PIXI.utils.skipHello();

type TabletProps = {
  tablet: TabletEntity;
  themeHex: ThemeHex;
  localizationStore: LocalizationStore;
  timeZonesStore: TimeZonesStore;
  scaleUnitStore: ScaleUnitStore;
};
export const Tablet = observer(({
  tablet,
  themeHex,
  localizationStore,
  timeZonesStore,
  scaleUnitStore,
}: TabletProps) => {
  const tabletRef = useRef<HTMLDivElement | null>(null);
  const stageRef = useRef<PixiStage | null>(null);
  const [visible, setVisible] = useState(true);

  const onFullScreen = useCallback(() => tablet.setFullscreen(false), [tablet]);
  const isFullscreen = useFullscreen(
    tabletRef,
    tablet.fullscreen,
    onFullScreen,
  );

  const [contextMenu] = useState(new ContextMenuStore(localizationStore));

  const bodyRef = useRef<HTMLDivElement>(null);

  const { height = 1, width = 1 } = useResizeObserver<HTMLDivElement>({ ref: bodyRef });

  useLayoutEffect(() => {
    tablet.params.setCanvasSize(width, height);
    tablet.tabletScroll.syncScrollTop();
  }, [tablet.params, height, width, tablet.tabletScroll]);

  const [{ isOver, canDrop, dragPosition }, drop] = useDrop(() => ({
    accept: ['well', 'curve'],
    drop: () => ({ name: 'TabletBody', track: tablet.coordinator.currentTrack, tablet }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      dragPosition: monitor.getClientOffset(),
    }),
  }), [tablet.coordinator.currentTrack, tablet]);

  drop(bodyRef);

  // const width = tablet.tracks.tracksWidth + tablet.widthScalePanel;

  useEffect(() => {
    const container = bodyRef.current;

    const setPosition = (ctrlKey: boolean, delta: number, offset: number) => {
      if (ctrlKey) {
        if (tablet.tracksAnalysis && tablet.tracksAnalysis.endedPosition === null) {
          return;
        }
        const zoomDirection = delta > 0 ? -1 : 1;
        tablet.zoom(offset, zoomDirection);
      } else if (!tablet.autoScroll) {
        tablet.scroll(delta);
      }
    };

    const scroll = (event: WheelEvent) => {
      event.preventDefault();
      if (tablet.params.orientation === 'vertical') {
        setPosition(event.ctrlKey, event.deltaY, (event as any).layerY);
      } else {
        setPosition(event.ctrlKey, event.deltaY, (event as any).layerX);
      }
    };
    container?.addEventListener('wheel', scroll);
    return () => {
      container?.removeEventListener('wheel', scroll);
    };
  }, [tablet]);

  const requestRunning = useRef<number | null>(null);

  function onMove(event: React.MouseEvent<HTMLCanvasElement>) {
    if (requestRunning.current === null) {
      tablet.coordinator.setPointerState(true);
      requestRunning.current = requestAnimationFrame(() => {
        const rect = (event.target as HTMLCanvasElement).getBoundingClientRect();
        tablet.coordinator.setPoint(
          event.clientX - rect.left,
          event.clientY - rect.top,
          event.clientX,
          event.clientY,
          event.shiftKey,
        );
        requestRunning.current = null;
      });
    }
  }
  function onMoveEnd() {
    tablet.coordinator.setPointerState(false);
    tablet.coordinator.setWithShift(false);
  }
  function onContextMenu(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
    event.preventDefault();
    const track = tablet.coordinator.currentTrack;
    if (track) {
      if (tablet.editMode) {
        contextMenu.openTrackEditMenu(event.clientX, event.clientY, tablet, track);
      } else {
        contextMenu.openTabletMenu(event.clientX, event.clientY, tablet, track);
      }
    }
  }

  const handleContextLost = useCallback(() => {
    if (stageRef.current) {
      const app = (stageRef.current as any).app as PIXI.Application;
      const renderer = app.renderer as PIXI.Renderer;
      const { context } = renderer;
      if (!document.hidden && context.isLost) {
        tablet.runGlNotAvailable();
      }
      setVisible(!document.hidden);
    }
  }, [tablet]);

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', handleContextLost);

    const app = (stageRef.current as any).app as PIXI.Application;
    const renderer = app.renderer as PIXI.Renderer;
    renderer.view.addEventListener('webglcontextlost', handleContextLost, false);
    return () => {
      document.removeEventListener('visibilitychange', handleContextLost);
      renderer.view.removeEventListener('webglcontextlost', handleContextLost);
    };
  }, [handleContextLost]);

  useEffect(() => {
    if (stageRef.current) {
      ((stageRef.current as any).app as PIXI.Application)
        .renderer.backgroundColor = themeHex.backgroundColor;
    }
  }, [themeHex.backgroundColor]);

  const curvesIsLoading = useMemo(() => {
    if (!tablet.sharedLoadingRange) {
      return false;
    }
    const start = Math.round(
      tablet.scale.dataToPoint(tablet.sharedLoadingRange.start) - tablet.scale.offsetY,
    );
    const end = Math.round(
      tablet.scale.dataToPoint(tablet.sharedLoadingRange.end) - tablet.scale.offsetY,
    );
    return end - start > 10;
  }, [tablet.scale, tablet.sharedLoadingRange]);

  const prevOffsetD = useRef(0);
  useGesture({
    onDrag: ({
      delta, tap, xy, event, canceled, pinching, cancel,
    }) => {
      if (canceled) {
        return;
      }
      if (pinching) {
        cancel();
        return;
      }
      if ((event as any)?.pointerType === 'mouse') {
        return;
      }
      if (tap) {
        tablet.coordinator.setPointerState(true);
        const rect = (event.target as HTMLCanvasElement).getBoundingClientRect();
        tablet.coordinator.setPoint(
          xy[0] - rect.left,
          xy[1] - rect.top,
          xy[0],
          xy[1],
        );
      } else {
        tablet.coordinator.setPointerState(false);
        tablet.scroll(-delta[1]);
      }
    },
    onPinch: ({
      origin, offset: [d],
    }) => {
      const zoomDirection = prevOffsetD.current > d ? -1 : 1;
      prevOffsetD.current = d;
      if (tablet.params.orientation === 'vertical') {
        tablet.zoom(origin[1], zoomDirection);
      } else {
        tablet.zoom(origin[0], zoomDirection);
      }
    },
  }, {
    target: bodyRef,
    enabled: tablet.editMode === false,
    drag: { filterTaps: true },
    pinch: {
      pinchOnWheel: false,
      // scaleBounds: { min: tablet.scale.maxZoom, max: tablet.scale.minZoom },
    },
  });

  useLayoutEffect(() => {
    if (!dragPosition || !bodyRef.current || tablet.coordinator.dragTrack) {
      return;
    }
    if (canDrop) {
      const rect = bodyRef.current.getBoundingClientRect();
      const x = dragPosition.x - rect.left;
      const y = dragPosition.y - rect.top;
      if (x < 0 || y < 0) {
        return;
      }
      tablet.coordinator.setPointerState(true);
      tablet.coordinator.setPoint(x, y);
    }
  }, [dragPosition, tablet.coordinator, canDrop]);

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Shift') {
        tablet.coordinator.setWithShift(true);
      }
    }
    function onKeyUp(e: KeyboardEvent) {
      if (e.key === 'Shift') {
        tablet.coordinator.setWithShift(false);
      }
    }
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);
    };
  }, [tablet.coordinator]);

  const bodyWidth = tablet.params.orientation === 'horizontal' || tablet.fullscreen
    ? '100%' : tablet.tracks.tracksWidth + tablet.scalePosition.tracksOffset;
  const { tracksAnalysis } = tablet;
  return (
    <TabletWrapper
      ref={tabletRef}
      style={{ width: tablet.params.orientation === 'horizontal' ? 'calc(100% - 20px)' : 'auto' }}
    >
      <TabletHeader tablet={tablet} isFullscreen={isFullscreen} />
      <TabletBody
        ref={bodyRef}
        style={{ width: bodyWidth }}
      >
        {tablet.trackInfoVisible
          && (
          <>
            <TabletTracksInfo tablet={tablet} contextMenu={contextMenu} position="top" />
            <TabletTracksInfo tablet={tablet} contextMenu={contextMenu} position="bottom" />
          </>
          )}
        {tablet.editMode === false && (tracksAnalysis
          ? <TracksAnalysis tablet={tablet} tracksAnalysis={tracksAnalysis} />
          : <CoordinatorTooltip tablet={tablet} />)}
        <ContextMenu contextMenu={contextMenu} />
        {!tablet.range && <CommentModal tablet={tablet} />}
        <ExportPdfModal />
        {tablet.editMode && tablet.tracks.withOffset.map(([track, offset]) => (
          track === tablet.tracks.selected && (
            <TrackSwitcher
              key={track.innerId}
              tablet={tablet}
              track={track}
              offset={offset + tablet.scalePosition.tracksOffset}
            />
          )
        ))}
        {tablet.tabletType === TabletType.Time ? (
          <NavigationTimeLine tablet={tablet} />
        ) : (
          <NavigationLine tablet={tablet} />
        )}
        {tablet.serviceInfo && <ScaleDevMonitor tablet={tablet} />}
        <ScrollBarHeight tablet={tablet} />
        <Stage
          ref={stageRef}
          contexts={[ThemeContext, FluentContext]}
          width={width}
          height={height}
          options={{
            antialias: window.devicePixelRatio < 2,
            resolution: Math.min(window.devicePixelRatio, 2),
          }}
          onMouseMove={(e) => onMove(e)}
          onMouseLeave={() => onMoveEnd()}
          onContextMenu={(e) => onContextMenu(e)}
        >
          {visible && (
          <TabletProvider
            tablet={tablet}
            themeHex={themeHex}
            localizationStore={localizationStore}
            timeZonesStore={timeZonesStore}
            scaleUnitStore={scaleUnitStore}
          >
            <ScrollContainer>
              <Tracks tablet={tablet} canDrop={canDrop} isOver={isOver} />
              <TracksBorders tablet={tablet} />
            </ScrollContainer>
            {tablet.editMode === false && tablet.coordinator.pointerMoving && (
            <Coordinator
              offsetX={0}
              offsetY={0}
              width={width}
              height={height}
              x={tablet.coordinator.x}
              y={tablet.coordinator.y}
            />
            )}
            <Sprite
              texture={PIXI.Texture.WHITE}
              x={tablet.scalePosition.x}
              y={tablet.scalePosition.y}
              width={tablet.scalePosition.width}
              height={tablet.scalePosition.height}
              tint={themeHex.backgroundColor}
            />
            {tablet.tabletType === TabletType.Time ? (
              <>
                <TimeFullScale />
                <TimeMainScale />
              </>
            ) : (
              <DepthScale tablet={tablet} key="depth-scale" />
            )}
            {tablet.coordinator.currentTrack && (
            <CoordinatorScale />
            )}
            <ScrollContainer>
              <OverlayTracks tablet={tablet} />
            </ScrollContainer>
          </TabletProvider>
          )}
        </Stage>
        {(curvesIsLoading || tablet.sourceDataMap.metaInfoLoading) && (
        <PointsLoadSpiner>
          <RotatingLines strokeColor={PIXI.utils.hex2string(themeHex.lightPrimary)} />
        </PointsLoadSpiner>
        )}
      </TabletBody>
    </TabletWrapper>
  );
});
