import * as PIXI from 'pixi.js';
import { Container, Sprite } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Tablet } from '../../stores/Tablet';
import { Track as TrackEntity } from '../../stores/Tracks';
import { DefectsChart } from './charts/DefectsChart';
import { CurveResolver } from './charts/CurveResolver';
import { TrackGrid } from './TrackGrid';
import { SelectedRange } from './SelectedRange';
import { TrackDropArea } from './TrackDropArea';
import { ContainerMask } from './ContainerMask';

type Props = {
  track: TrackEntity,
  tablet: Tablet;
  offset: number;
  canDrop: boolean,
  maskTo?: number,
};

export const Track = observer(({
  track, tablet, offset, canDrop, maskTo,
}: Props) => {
  const {
    trackWidth, gridBaseVert, gridSecondVert, valueScaleType,
  } = track.params;

  const [cuttingsLogValues, rangesDefectData] = track.cuttingData;

  const isHovered = tablet.editMode && tablet.coordinator.currentTrack === track;
  const isSelected = tablet.editMode && tablet.tracks.selected === track;

  const { offsetY } = tablet.scale;
  const height = tablet.scale.containerLength;
  const maskHeight = useMemo(() => {
    if (maskTo) {
      const y = tablet.scale.dataToPoint(maskTo);
      if (offsetY > (y - height)) {
        const h = height - Math.ceil(offsetY - y + height);
        return h;
      }
    }
    return height;
  }, [height, maskTo, offsetY, tablet.scale]);

  function onPointerUp() {
    if (tablet.tracksAnalysis) {
      tablet.tracksAnalysis.end();
      document.removeEventListener('pointerup', onPointerUp);
    }
  }

  function onPointerDown() {
    if (tablet.editMode) {
      tablet.tracks.setSelected(track);
    } else if (tablet.range) {
      if (tablet.range.from == null) {
        tablet.range.start();
      } else {
        tablet.range.end();
      }
    } else {
      tablet.startTracksAnalysis();
      document.addEventListener('pointerup', onPointerUp);
    }
  }

  const dragOffset = tablet.coordinator.dragTrack === track
    ? (tablet.params.orientation === 'vertical'
      ? (tablet.coordinator.x + tablet.tabletScroll.scrollLeft)
      : (tablet.tabletScroll.height - tablet.coordinator.y
          + tablet.tabletScroll.scrollTop)) - trackWidth / 2
    : offset;

  return (
    <Container
      position={[dragOffset, 0]}
      buttonMode
      interactive
      pointerdown={() => onPointerDown()}
    >
      <Sprite
        x={0}
        y={0}
        width={trackWidth}
        height={height}
        texture={PIXI.Texture.WHITE}
        alpha={isHovered || isSelected ? 0.075 : 0}
        tint={0x000000}
      />
      {canDrop && isHovered && (
        <TrackDropArea tablet={tablet} track={track} />
      )}
      <TrackGrid
        height={height}
        trackWidth={trackWidth}
        gridBaseVert={gridBaseVert}
        gridSecondVert={gridSecondVert}
        valueScaleType={valueScaleType}
        offsetX={0}
      />
      <ContainerMask
        width={trackWidth}
        height={maskHeight}
      >
        {track.sourcesSorted.map((curve) => (
          <CurveResolver
            key={curve.sourceDto.externalId}
            tablet={tablet}
            source={curve}
            track={track}
            width={trackWidth}
            cuttingsLogValues={cuttingsLogValues}
            offsetX={0}
            trackSelected={isSelected}
          />
        ))}
        {rangesDefectData && (
        <DefectsChart
          tablet={tablet}
          offsetX={0}
          rangesDefectData={rangesDefectData}
          width={trackWidth}
        />
        )}
      </ContainerMask>
      {
        tablet.tracksAnalysis
        && tablet.tracksAnalysis.selectedTracks.includes(track)
        && tablet.tracksAnalysis.from
        && (
        <SelectedRange
          width={trackWidth}
          height={height}
          from={tablet.scale.dataToPoint(tablet.tracksAnalysis.from) - tablet.scale.offsetY}
          to={tablet.scale.dataToPoint(tablet.tracksAnalysis.to) - tablet.scale.offsetY}
        />
        )
      }
      {
        tablet.range
        && tablet.range.track === track
        && tablet.range.from
        && tablet.range.to && (
        <SelectedRange
          width={trackWidth}
          height={height}
          from={tablet.range.from - tablet.scale.offsetY}
          to={tablet.range.to - tablet.scale.offsetY}
        />
        )
      }
    </Container>
  );
});
