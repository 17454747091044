import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { XYCoord } from 'react-dnd';

export const TreeWrapper = styled.div`
  padding: 10px;
  position: relative;
  &:not(:hover) {
    .ScrollbarsCustom-Track {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
    }
  }
`;

export const TreeNodeBlock = styled.div((props: { level: number }) => ({
  paddingLeft: props.level === 0 ? 0 : 20,
}));

export const TreeNodeInner = styled.div`
  display: flex;
  &:hover {
    background-color: rgba(0, 0, 0, .075);
  }
`;

export const TreeIconWrapper = styled.span`
  width: 20px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  flex-shrink: 0;
  user-select: none;
  padding-top: 4px;
`;

export const TreeContentWrapper = styled.div`
  flex: auto;
  padding: 0 4px;
  white-space: nowrap;
  cursor: pointer;
`;

export const TreeFolderImage = styled.img`
  display: inline-block;
  width: 16px;
  height: 16px;
`;

export const HeaderActions = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.backgroundColor};
  z-index: 1000;
  height: 40px;
  padding: 8px;
`;

export const NodeDragBlock = styled.div<{ currentOffset: XYCoord | null }>`
  ${({ currentOffset }) => (currentOffset
    ? css`transform: ${`translate(${currentOffset.x}px, ${currentOffset.y}px);`};`
    : css`display: none;`)};
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  pointer-events: none;
  font-size: 12px;
  line-height: 16px;
  border: ${(props) => props.theme.baseBorder};
  background-color: ${(props) => props.theme.backgroundColor};
`;
