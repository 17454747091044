import * as PIXI from 'pixi.js';
import { Graphics } from '@inlet/react-pixi';
import { useCallback, useContext } from 'react';
import { ValueScaleType } from '../../api/dto/TrackDto';
import { TabletContext } from './TabletProvider';

type Props = {
  height: number;
  trackWidth: number;
  gridBaseVert: number;
  gridSecondVert: number;
  valueScaleType: ValueScaleType;
  offsetX: number;
};
export function TrackGrid({
  height, trackWidth, gridBaseVert, gridSecondVert, valueScaleType, offsetX,
}: Props) {
  const { themeHex } = useContext(TabletContext);

  const draw = useCallback((g: PIXI.Graphics) => {
    g.clear();
    g.lineStyle(1, themeHex.gridColor, 0.3);
    if (valueScaleType === ValueScaleType.Log) {
      const scaleLog = 10; // размер лог шкалы

      const distance = Math.round((trackWidth / gridBaseVert) * 100) / 100;
      const difference = distance / Math.log10(scaleLog);
      const val = scaleLog / gridSecondVert; // шаг по лог шкале
      let x: number;
      for (let i = 0; i < gridBaseVert; i += 1) {
        g.moveTo(distance * i + offsetX, 0);
        g.lineTo(distance * i + offsetX, height);
      }
      for (let j = 0; j < gridBaseVert; j += 1) {
        for (let i = 1; i < gridSecondVert; i += 1) {
          x = Math.round(
            Math.log10(val * i) * difference + distance * j,
          );
          if (x !== distance || x < trackWidth - 2 || x > 2) {
            g.moveTo(x + offsetX, 0);
            g.lineTo(x + offsetX, height);
          }
        }
      }
    }
    if (valueScaleType === ValueScaleType.Linear) {
      const stepBaseVert = +(trackWidth / gridBaseVert).toFixed(1);
      const stepSecondVert = +(trackWidth / (gridBaseVert * gridSecondVert)).toFixed(1);

      for (let i = 0; i < gridBaseVert; i += 1) {
        for (let j = 0; j < gridSecondVert; j += 1) {
          g.moveTo(
            stepBaseVert * i + stepSecondVert * j,
            0,
          );
          g.lineTo(
            stepBaseVert * i + stepSecondVert * j,
            height,
          );
        }
      }
    }
    if (valueScaleType === ValueScaleType.Comments) {
      g.moveTo(0, 0);
      g.lineTo(0, height);
    }
  }, [
    themeHex.gridColor,
    valueScaleType,
    trackWidth,
    gridBaseVert,
    gridSecondVert,
    offsetX,
    height,
  ]);
  return <Graphics draw={draw} />;
}
