import { Curve } from '../../stores/Tracks';
import { CoordinatorBadge as CoordinatorBadgeStyled, CoordinatorScaleBadge } from './TabletTracksInfo.styled';

export function CoordinatorBadge({
  x, trackWidth, sourceParams, scaleFrom, scaleTo, rt = 'vertical', showValueScale = false, reverse = false,
}:
{
  x: number;
  trackWidth: number;
  sourceParams: Curve['params'];
  scaleFrom: number;
  scaleTo: number;
  rt?: 'vertical' | 'horizontal';
  showValueScale?: boolean;
  reverse?: boolean;
}) {
  if (showValueScale) {
    return (
      <CoordinatorScaleBadge color={sourceParams.color} x={x} rt={rt} reverse={reverse}>
        {Math.ceil(
          scaleFrom + ((scaleTo - scaleFrom) / trackWidth) * x,
        )}
      </CoordinatorScaleBadge>
    );
  }
  return (
    <CoordinatorBadgeStyled color={sourceParams.color} x={x} reverse={reverse}>
      {Math.ceil(
        scaleFrom + ((scaleTo - scaleFrom) / trackWidth) * x,
      )}
    </CoordinatorBadgeStyled>
  );
}
